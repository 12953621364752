// composables/useTracking.js
export default function useTracking() {
    function trackingUserDetails(user) {
      // Implement user tracking details logic
    }
  
    function trackingPurchaseJourney(eventName, user, product) {
      if (process.client && window.dataLayer) {
        if (eventName === 'CompletePayment') {
          window.dataLayer.push({
            transaction_id: product.transaction_id,
            payment_method: product.payment_method,
            price: product.totalPrice,
            user_id: product.user_id,
            phone_number: product.phone_number,
          });
        }
  
        if (Array.isArray(product)) {
          product.forEach((item) => {
            window.dataLayer.push({
              event: eventName,
              user_name: `${user.first_name} ${user.last_name}`,
              user_email: user.email,
              country_name: user.country_name,
              gender: user.gender === 'ذكر' ? 'male' : 'female',
              item: [{
                item_name: item.name,
                item_id: item.id,
                num_items: 1,
                item_type: item.model_type ? item.model_type : item.type,
                item_price: item.price,
              }],
              value: parseFloat(item.price),
              currency: item.currency ? item.currency : 'SAR',
            });
          });
        } else if (eventName === 'viewItemSkipped') {
          window.dataLayer.push({
            event: eventName,
          });
        } else {
          window.dataLayer.push({
            event: eventName,
            user_name: `${user.first_name} ${user.last_name}`,
            user_email: user.email,
            country_name: user.country_name,
            gender: user.gender === 'ذكر' ? 'male' : 'female',
            item: [{
              item_name: product.name ? product.name : product.productsNames,
              item_id: product.id ? product.id : product.productsIds,
              num_items: product.numberOfProducts ? product.numberOfProducts : 1,
              item_type: product.model_type ? product.type : 'product',
              item_price: product.price ? product.price : product.totalPrice,
            }],
            value: parseFloat(product.price ? product.price : product.totalPrice),
            currency: product.currency ? product.currency : 'SAR',
          });
        }
      }
    }
  
    return {
      trackingUserDetails,
      trackingPurchaseJourney,
    };
  }
  